import { ReactElement, useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import SideBar from '../../components/SideBar/SideBar';
import NavBar from '../../components/NavBar/NavBar';
import About from '../About/About';
import Articles from '../Articles/Articles';
import Project from '../Projects/Projects';
import Home from '../Home/Home';
import styles from './App.module.scss';
import ScrollButton from '../../components/ScrollButton/ScrollButton';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { HeaderModel } from '../../models/headermodel.model';
import { HeaderService } from '../../services/header-service';
import { Divide } from 'hamburger-react';
import NewProjectCard from '../../components/NewProjectCard/NewProjectCard';
import NewIllustrationContainer from '../../components/NewIllustrationContainer/NewIllustrationContainer';
import ProjectPage from '../../components/ProjectPage/ProjectPage';

function App(): ReactElement {
    const dadRef = useRef<null | HTMLDivElement>(null);
    const illRef = useRef<null | HTMLDivElement>(null);
    const aboutRef = useRef<null | HTMLDivElement>(null);

    function scrollToTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    function scrollToDad(): void {
        dadRef.current?.scrollIntoView({
            behavior: 'smooth',
        });
    }

    function scrollToIll(): void {
        illRef.current?.scrollIntoView({
            behavior: 'smooth',
        });
    }

    function scrollToAbout(): void {
        aboutRef.current?.scrollIntoView({
            behavior: 'smooth',
        });
    }

    return (
        <div className={styles['under-contruction']}>
            <p>Under Construction</p>
            {/* <Switch>
                <Route exact path="/" component={Home} />
                <Route
                    path="/project/TheWalrusWebRedesign"
                    component={Project}
                />
                <Route path="/project/HopeYoureWell" component={Project} />
                <Route
                    path="/project/TheConversationPiece"
                    component={Project}
                />
                <Route path="/project/FallBooks" component={Project} />
            </Switch> */}
        </div>
    );
}

export default App;
